import React, { useEffect } from "react";
// Icons
import speaker from "../../style/assets/icons/speaker.svg";
import speakerOff from "../../style/assets/icons/speakerOff.svg";
import copyright from "../../style/assets/icons/copyright.svg";
import subtitles from "../../style/assets/icons/subtitles.svg";
import subtitlesOff from "../../style/assets/icons/subtitlesOff.svg";
import close from "../../style/assets/icons/closeVideo.svg";
import { GetTrad } from "../Partials/GetTrad";

export const Menu = (props: any) => {
	useEffect(() => {}, [props.sound]);

	const goCredits = () => {
		if (props.displayCredits) {
			let canvasElem = document.getElementById("viewport");

			if (canvasElem) {
				canvasElem.style.width = "0";
				canvasElem.style.height = "0";
			}
			props.displayCredits(true);
		}
	};

	return (
		<div id="menu">
			<div
				id='button0'
				className='clickable'
				onClick={() => {
					props.back();
				}}
				style={{
					position: "absolute",
					top: "20px",
					right: "20px",
					width: "52px",
					height: "52px",
					zIndex: 20000,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column",

					borderRadius: "50%",
					backgroundColor: "rgba(0, 0, 0, 0.2)",
					backgroundImage: `url(${close})`,
					backgroundSize: "20px 20px",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
				}}
			></div>

			<div
				id='button1'
				className='clickable'
				onClick={() => {
					props.toggleSound();
				}}
				style={{
					position: "absolute",
					top: "88px",
					right: "20px",
					width: "52px",
					height: "52px",
					zIndex: 20000,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column",

					borderRadius: "50%",
					backgroundColor: "rgba(0, 0, 0, 0.2)",
					backgroundImage: props.sound ? `url(${speaker})` : `url(${speakerOff})`,
					backgroundSize: "24px 24px",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
				}}
			></div>

			{/* <div
				id='button2'
				className='clickable'
				onClick={() => {
					props.toggleSubtitles();
				}}
				style={{
					position: "absolute",
					top: "156px",
					right: "20px",
					width: "52px",
					height: "52px",
					zIndex: 20000,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column",

					borderRadius: "50%",
					backgroundColor: "rgba(0, 0, 0, 0.2)",
					backgroundImage: props.subtitles ? `url(${subtitles})` : `url(${subtitlesOff})`,
					backgroundSize: "24px 24px",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
				}}
			></div> */}

			<div
				id='button3'
				className='clickable'
				style={{
					position: "absolute",
					top: "156px",
					right: "20px",
					width: "52px",
					height: "52px",
					zIndex: 20000,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column",

					borderRadius: "50%",
					backgroundColor: "rgba(0, 0, 0, 0.2)",
					backgroundImage: `url(${copyright})`,
					backgroundSize: "26px 26px",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
				}}
				onClick={() => {
					goCredits();
				}}
			></div>

            <div
				id='button4'
				className='clickable'
				style={{
					position: "absolute",
					top: "224px",
					right: "20px",
					width: "52px",
					height: "52px",
					zIndex: 20000,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column",

					borderRadius: "50%",
					backgroundColor: "rgba(0, 0, 0, 0.2)",
					/*backgroundImage: `url(${copyright})`,*/
					backgroundSize: "26px 26px",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
                    color: "#ecebe9",
                    textAlign: "center",
                    fontSize: "11px"
				}}
				onClick={() => {
					props.toggleQuality();
				}}
			>{props.quality ? <GetTrad value={ "qualityHigh" } lang={props.language} /> : <GetTrad value={ "qualityLow" } lang={props.language} />}</div>
		</div>
	);
};
