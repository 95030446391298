// Modules
import * as THREE from "three";
import { MeshBasicMaterial } from "three";

// GJ modules
import {
	Pawn,
	Player,
	FikscopeObject3D,
	FikscopeEngine,
	FikscopeLevel,
	Actor,
	Sphero_cons_type,
} from "../../../fikscopeengine_modules/fikscopeengine-core";

// Project
import { PSphere } from "./../pawn/PSphere";

export type LMSphere_cons_type = {
	lang: string;
	data?: any;
	setPoi?: Function;
};

export class LMSphere extends FikscopeLevel {
	actors: Map<string, Actor>;
	sphere?: THREE.Mesh;
	name: string;
	lang: string;

	video: HTMLVideoElement | null;
	textureVideo?: THREE.Texture;

	initPos: THREE.Vector3 = new THREE.Vector3(0, 0, 1.5);
	ZERO_QUATERNION: THREE.Quaternion = new THREE.Quaternion(0, 0, 0, 1);
	initQuat: THREE.Quaternion = this.ZERO_QUATERNION;
	onStart: (args: any) => void;
	onStop: (args: any) => void;

	fe?: FikscopeEngine;

	data: any;
	public setPoi: Function | undefined;

	public currentSelection: string | null = null;

	constructor({ lang, data, setPoi }: LMSphere_cons_type) {
		super();

		this.lang = lang;
		this.data = data;
		this.setPoi = setPoi;

		this.actors = new Map<string, Actor>();
		this.onStart = (e) => this.start();
		this.onStop = () => {
			console.warn("stop");
		};
		this.name = LMSphere.name;
		this.pawnPlayer = (fe) => this.defaultPawnPlayer(fe);

		const video = document.getElementById("video");

		this.video = video as HTMLVideoElement;

		if (this.video !== null) {
			this.textureVideo = new THREE.VideoTexture(this.video);
		}
	}

	changeTexture(texture: string) {
		console.log("Changing");
		if (this.sphere) {
			var video = document.getElementById("video") as HTMLVideoElement;
			if (video) {
				video.src = texture;
				video.crossOrigin = "anonymous"; // required for run on file:/
				video.autoplay = true;
				video.loop = true;
				video.playsInline = true;

				this.textureVideo = new THREE.VideoTexture(video);
				(this.sphere.material as MeshBasicMaterial).color = new THREE.Color(
					0xffffff
				);
				(this.sphere.material as MeshBasicMaterial).map = this.textureVideo;
				(this.sphere.material as MeshBasicMaterial).needsUpdate = true;
			}
		}
	}

	newPlayer(fe: FikscopeEngine, nick: string, id: string): Player {
		let pawn = this.pawnPlayer(fe);
		let p = new Player(nick, id, pawn);
		fe.players.set(id, p);
		return p;
	}

	defaultPawnPlayer(fe: FikscopeEngine): Pawn<FikscopeObject3D> {
		var redMaterial = new THREE.MeshStandardMaterial({
			color: 0xff2222,
			depthWrite: true,
			depthTest: true,
			metalness: 0.8,
			opacity: 1,
		});
		var ZERO_QUATERNION = new THREE.Quaternion(0, 0, 0, 1);

		return new PSphere({
			fe: fe,
			material: redMaterial,
			pos: new THREE.Vector3(0, 0, 1.5),
			quat: ZERO_QUATERNION,
			r: 0.0001,
			mass: 100,
			friction: 0,
		} as Sphero_cons_type);
	}

	start(): void {
		console.log("start LMSphere360");
		/* log(this.stopWatch) */
		/* this.stopWatch.mystart(); */
	}

	update(dt: number) {
		if (this.data) {
			if (this.data.pois) {
				for (let p of this.data.pois) {
					let domElement = document.getElementById(p.short);

					if (domElement) {
						//domElement.style.top = "10%"

						if (this.fe && this.fe.camera) {
							var po = new THREE.Vector3(
								p.position.x,
								p.position.y,
								p.position.z
							);
							var vector = po.project(this.fe.camera);

							vector.x = (vector.x + 1) / 2;
							vector.y = -(vector.y - 1) / 2;

							if (vector.z > 1) {
								vector.x = -20;
								vector.y = -20;
								if (this.currentSelection === p.short) {
									this.currentSelection = null;
									if (this.setPoi) {
										this.setPoi(null);
									}
								}
							}

							(p.positionScreen as THREE.Vector2).set(vector.x, vector.y);

							domElement.style.top = vector.y * 100 + "%";
							domElement.style.left = vector.x * 100 + "%";
						}
					}
				}
			}
		}
	}

	init(fe: FikscopeEngine) {
		//Scene
		console.log("  └ FL - LMSphere loading...");
		this.fe = fe;

		const texturePoi = new THREE.TextureLoader().load(
			"./assets/textures/poi_zoom.png"
		);
		/*texturePoi.wrapS = THREE.RepeatWrapping;
		texturePoi.wrapT = THREE.RepeatWrapping;
		texturePoi.repeat.set( 0.125, 0.125);
		texturePoi.offset.set(0.250, 0.875)*/

		if (this.data) {
			if (this.data.pois) {
				for (let p of this.data.pois) {
					let material = new THREE.SpriteMaterial({
						map: texturePoi,
						color: 0xffffff,
					});
					let sprite = new THREE.Sprite(material);
					fe.scene.add(sprite);
					sprite.position.set(p.position.x, p.position.y, p.position.z);
					sprite.scale.set(7, 7, 7);
					console.log(sprite);
				}
			}
		}

		//Lighting
		var ambient = new THREE.AmbientLight(0xffffff, 1);
		fe.scene.add(ambient);
		var directionalLight = new THREE.DirectionalLight(0xffffff, 0);
		fe.scene.add(directionalLight);

		var video = document.createElement("video");
		//video.src = "video/video360.mp4";
		video.crossOrigin = "anonymous"; // required for run on file:/
		video.autoplay = true;
		video.loop = true;

		var mat = new THREE.MeshBasicMaterial({ map: this.textureVideo });
		var geom = new THREE.SphereGeometry(500, 32, 32);
		geom.applyMatrix4(new THREE.Matrix4().makeScale(1, 1, -1));
		this.sphere = new THREE.Mesh(geom, mat);
		fe.scene.add(this.sphere);

		// Pawn
		let currentPlayer = null;

		if (fe.INatscope && fe.INatscope.natscope.me) {
			currentPlayer = this.newPlayer(
				fe,
				fe.INatscope.natscope.me.nick,
				fe.INatscope.natscope.me.id
			);
		} else {
			currentPlayer = this.newPlayer(fe, "me", "me");
		}

		if (currentPlayer.pawn) {
			this.actors.set("player", currentPlayer.pawn);

			//(currentPlayer.pawn as PSphere).level = this; // Not in FiktivLevel ?

			if (currentPlayer.pawn.controller) {
				if (fe.inputManager) {
					fe.inputManager.setInputReceiver(currentPlayer.pawn.controller);
				}
			} else {
				console.error("  └ FL - No Controller for current player pawn");
			}
		}

		console.log("  └ FL - LMSphere loaded");
	}
}
