export const trads = [
	{
		id: "fr",

		homeEnter1: "Entrez et explorez",
		homeEnter2: "le château de Marly !",
		homeCollab: "En collaboration avec Timescope",

		// IntroPage
		introTitle: "Parfums de Marly",
		introSubtitle:
			"Parfums de Marly vous ouvre les portes du château de Marly au XVIII%ee%e siècle, où l'art de vivre à la française et la culture du parfum s'incarnent avec un raffinement extrême, en toute liberté.",
		new: "Nouveau",

		// InfoPage
		strollTitlePart1: "Une promenade",
		strollTitlePart2: "à Marly",
		strollSubtitle:
			"Poussez les portes du domaine pour une promenade dans les jardins de Marly, un après-midi de printemps.",
		strollText1:
			"Découvrez cet écrin de verdure enchanteur réservé aux plus proches amis du roi, percez le mystère de l’architecture exceptionnelle du château de Marly, et imprégnez-vous de son atmosphère unique, où la singularité règne en maître.",
		strollShareHeader: "Une promenade à Marly",

		feastTitlePart1: "Marly",
		feastTitlePart2: "est une fête",
		feastSubtitle:
			"Entrez dans l'univers privilégié des ‘Marlys', ces séjours festifs d’exception.",
		feastText1:
			"Flânez à travers les magnifiques jardins du domaine et profitez des nombreux jeux d’extérieur. Lorsque la nuit tombe, laissez-vous emporter par la magie des festivités dans une atmosphère envoûtante, où liberté et plaisir sont au centre des célébrations.",
		feastShareHeader: "Marly est une fête",

		// Credits
		credits: "Crédits",
		creditsContent:
			"Cette expérience de « voyage dans le temps » est le fruit d'un partenariat entre Parfums de Marly et la société Timescope, pionnière de l'innovation culturelle au service de la valorisation du patrimoine.",
		creditsMusic: "Musique",
		creditsMusicContent: `Le Bourgeois Gentilhomme, LWV 43: Marche pour la cérémonie des Turcs`,
		creditsMusicContent1: `Jean-Baptiste Lully`,
		creditsMusicContent2: `Capriccio Stravagante Orchestra, Skip Sempé`,
		creditsMusicContent3: `© & ℗ ALPHA CLASSICS / OUTHERE MUSIC FRANCE`,
		share: "Partager",

		// Endpage
		experienceProposedBy: "Une expérience en réalité virtuelle réalisée par Timescope",

        // Player
        qualityHigh: "4k",
        qualityLow: "1080p",
	},
	{
		id: "en",

		homeEnter1: "Enter and explore",
		homeEnter2: "the château de Marly!",
		homeCollab: "In collaboration with Timescope",

		// IntroPage
		introTitle: "Parfums de Marly",
		introSubtitle:
			"Parfums de Marly opens the doors of the 18%eth%e century Château de Marly, where the French art of living and the culture of perfume are embodied with the utmost refinement and freedom.",
		new: "New",

		// InfoPage
		strollTitlePart1: "A stroll",
		strollTitlePart2: "through Marly",
		strollSubtitle:
			"Push open the gates of the estate for a stroll through the gardens of Marly on a spring afternoon.",
		strollText1:
			"Discover this enchanting green setting reserved for the King's closest friends, discover the mystery of the Château de Marly's exceptional architecture, and enjoy its unique atmosphere, where singularity reigns supreme.",
		strollShareHeader: "A stroll though Marly",

		feastTitlePart1: "Marly",
		feastTitlePart2: "is a feast",
		feastSubtitle:
			"Step into the privileged world of the 'Marlys', these exceptional festive retreats.",
		feastText1:
			"Stroll through the magnificent gardens of the estate and enjoy the various outdoor games. As night falls, let yourself be swept away by the magic of the festivities in a captivating atmosphere, where freedom and pleasure are at the heart of the celebrations.",
		feastShareHeader: "Marly is a feast",

		// Credits
		credits: "Credits",
		creditsContent:
			"This « time travel » experience is the result of a partnership between Parfums de Marly and Timescope, a pioneer in cultural innovation serving heritage promotion.",
		creditsMusic: "Music",
		creditsMusicContent: `Le Bourgeois Gentilhomme, LWV 43: Marche pour la cérémonie des Turcs
		Jean-Baptiste Lully
		Capriccio Stravagante Orchestra, Skip Sempé
		© & ℗ ALPHA CLASSICS / OUTHERE MUSIC FRANCE`,
		share: "Share",

		// EndPage
		experienceProposedBy: "A virtual reality experience by Timescope",

        // Player
        qualityHigh: "4k",
        qualityLow: "1080p",
	},
];
