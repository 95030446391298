// Eats
import eatsTitleImg from "../style/assets/infoPage/stroll/title.png";
import strollAfficheNormalImg from "../style/assets/infoPage/stroll/affiche.jpg";
import strollAfficheBlurImg from "../style/assets/infoPage/stroll/afficheBlur.jpg";
import feastAfficheNormalImg from "../style/assets/infoPage/feast/affiche.jpg";
import feastAfficheBlurImg from "../style/assets/infoPage/feast/afficheBlur.jpg";

export const experiences = [
	{
		id: "feast",
		title: {
			valuePart1: "feastTitlePart1",
			valuePart1Title: false,
			valuePart2: "feastTitlePart2",
			valuePart2Title: true,
			img: eatsTitleImg,
		},
		affiche: {
			normal: feastAfficheNormalImg,
			blur: feastAfficheBlurImg
		},
		content: [
			{
				type: "title",
				value: "feastSubtitle",
			},
			{
				type: "text",
				value: "feastText1",
			},
		],
		endPage: [
			{
				type: "text",
				value: "experienceProposedBy",
			}
		],
		movie: {
			title: "feastShareHeader",
			vignette: eatsTitleImg,
			new: true,
			duration: "2:38",
		},
		share: {
			header: "feastShareHeader",
		},
	},
	{
		id: "stroll",
		title: {
			valuePart1: "strollTitlePart1",
			valuePart1Title: true,
			valuePart2: "strollTitlePart2",
			valuePart2Title: false,
			img: eatsTitleImg,
		},
		affiche: {
			normal: strollAfficheNormalImg,
			blur: strollAfficheBlurImg
		},
		content: [
			{
				type: "title",
				value: "strollSubtitle",
			},
			{
				type: "text",
				value: "strollText1",
			},
		],
		endPage: [
			{
				type: "text",
				value: "experienceProposedBy",
			},
		],
		movie: {
			title: "strollShareHeader",
			vignette: eatsTitleImg,
			new: true,
			duration: "3:03",
		},
		share: {
			header: "strollShareHeader",
		},
	},
];
