// Assets
import loadingGif from '../../style/assets/load.gif';

export const Loader = (props: any) => {
	return (
		<div
			id='loader'
			style={{
				zIndex: 8000,
				position: 'absolute',
				top: 0,
				width: '100%',
				height: '100%',
				pointerEvents: 'none',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				alignItems: 'center',
				opacity:0
			}}
		>
			<div
				style={{
					width: '15%',
					height: '15%',

					backgroundImage: `url(${loadingGif})`,
					backgroundSize: 'contain',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',

					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						fontFamily: 'OswaldBold',
						fontSize: '1.4em',
						borderRadius: '5px',
						color: 'white'
					}}
				>
					{props.buffered + '%'}
				</div>
			</div>
		</div>
	);
};
