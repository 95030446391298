// React
import { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// Components
import { Home } from './components/home/Home';

function App() {
	useEffect(() => {
		if (document.location.search.slice(1) === 'qrcode=true') {
			window.history.pushState({}, document.title, window.location.pathname);
		}
	}, []);

	let details = navigator.userAgent;
	let regexp = /android|iphone|kindle|ipad/i;
	let isMobileDevice = regexp.test(details);

	console.log(isMobileDevice ? 'Launch Mobile WebApp' : 'Launch Desktop WebApp')

	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={isMobileDevice ? <Home /> : <Home />} />
				<Route path='/:id' element={isMobileDevice ? <Home /> : <Home />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
