// Styles
import "./credits.css";
// Icons
import { useSelector } from "react-redux";
import { IRootState } from "../../reducers";
import { trads } from "../../datas/trads";
import timescopeLogo from "../../style/assets/landingPage/logoTimescope.svg";

export const Credits = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.user);

	return (
		<div className='credits'>
			<div className='credits-overflowed'>
				<div className='credits-close' onClick={() => props.goBack()} />
				<div className='credits-content'>
					<div className='credits-title'>{trads.find((t: any) => t.id === language)?.credits}</div>
					<div className='credits-text'>{trads.find((t: any) => t.id === language)?.creditsContent}</div>
					<div className='credits-content-title'>{trads.find((t: any) => t.id === language)?.creditsMusic}</div>
					<div className='credits-text'>{trads.find((t: any) => t.id === language)?.creditsMusicContent}</div>
					<div className='credits-text'>{trads.find((t: any) => t.id === language)?.creditsMusicContent1}</div>
					<div className='credits-text'>{trads.find((t: any) => t.id === language)?.creditsMusicContent2}</div>
					<div className='credits-text'>{trads.find((t: any) => t.id === language)?.creditsMusicContent3}</div>
				</div>

				<div className='infoPage-footer'>
					<div className='landingPage-logos'>
						<div className='landingPage-logos-logoMarly'></div>
						<div
							className='landingPage-logos-logoTimescope'
							style={{
								backgroundImage: `url(${timescopeLogo})`,
							}}
						></div>
					</div>
					<div className='infoPage-blankBottom'></div>
				</div>
			</div>
		</div>
	);
};
