// React
import { useEffect, useState } from "react";
// Trads
import { trads } from "../../datas/trads";
// CSS
import "./landingPage.css";
// Experiences
import { experiences } from "../../datas/experiences";
// Assets
import timescopeLogo from "../../style/assets/landingPage/logoTimescope.svg";
import { GetTrad } from "../Partials/GetTrad";
import { useSelector } from "react-redux";
import { IRootState } from "../../reducers";

export const LandingPage = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.user);

	useEffect(() => {
		console.log("Display Landing", props.landingPage);

		let homeElem = document.getElementsByClassName("home");
		if (homeElem) {
			for (let elem of homeElem) {
				(elem as HTMLElement).style.pointerEvents = "all";
			}
		}

		const scrollManagment = (e?: Event) => {
			let elem = document.getElementById("landingPage-container");
			let elemOpacity = document.getElementById("landingPage-textContainer");
			if (elem && elemOpacity) {
				let { scrollHeight, scrollTop, clientHeight } = elem;

				let max = scrollHeight - clientHeight;
				let percent = ((scrollHeight - scrollTop - clientHeight) * 100) / max;

				if (percent < 0) {
					percent = 0;
				}

				elemOpacity.style.setProperty("--maskHeight", 100 - percent + "%");

				let elemArrow = document.getElementById("landingPage-textScroll");
				if (elemArrow) {
					if (scrollHeight - scrollTop - 15 > clientHeight) {
						elemArrow.style.opacity = "1";
					} else {
						elemArrow.style.opacity = "0";
					}
				}
			}
		};
		scrollManagment();

		document.getElementById("landingPage-container")?.addEventListener("scroll", (e: Event) => scrollManagment(e));

		return () =>
			document
				.getElementById("landingPage-container")
				?.removeEventListener("scroll", (e: Event) => scrollManagment(e));
	}, [props.landingPage]);

	const [experience, setExperience] = useState<any>();
	//const [otherExperience, setOtherExperience] = useState<any>();

	useEffect(() => {
		setExperience(experiences.find((e: any) => e.id === props.landingPage));
		/*setOtherExperience(
			experiences.find((e: any) => e.id !== props.landingPage)
		);*/
	}, [props.landingPage]);

	if (!experience) {
		return <></>;
	}

	return (
		<div className='landingPage'>
			<div className='landingPage-overflowed'>
				<div id='intro-header' className='intro-header'>
					<div className='intro-header-back' onClick={() => props.back()}></div>
					<div className='intro-header-logo'></div>
				</div>

				<div style={{ height: "70%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
					<div className='infoPage-intro'>
						<div className={experience.title.valuePart1Title ? 'infoPage-title' : 'infoPage-subtitle'}>
							{(trads.find((t: any) => t.id === language) as any)[experience.title.valuePart1]}
						</div>
						<div className={experience.title.valuePart2Title ? 'infoPage-title' : 'infoPage-subtitle'}>
							{(trads.find((t: any) => t.id === language) as any)[experience.title.valuePart2]}
						</div>
					</div>
					<br />

					<div className='landingPage-content'>
						{experience.endPage.map((c: any) => {
							switch (c.type) {
								case "title":
									return (
										<div key={c.value} className='landingPage-content-title'>
											<GetTrad value={c.value} lang={language} />
										</div>
									);
								case "text":
									return (
										<div key={c.value} className='landingPage-content-text'>
											<GetTrad value={c.value} lang={language} />
										</div>
									);
								default:
									return <></>;
							}
						})}
					</div>
					<br />

					<div
						className='infoPage-share'
						onClick={() => {
							navigator.share({
								url: "/" + experience.id,
								text: (trads.find((t: any) => t.id === language) as any)[experience.share.header],
								title: (trads.find((t: any) => t.id === language) as any)[experience.share.header],
							});
						}}
					>
						<div className='infoPage-share-icon'></div>
						<div className='infoPage-share-text'>
							{trads.find((t: any) => t.id === language)?.share.toUpperCase()}
						</div>
					</div>
				</div>
				<br />
				<div className='infoPage-footer'>
					<div className='landingPage-logos'>
						<div
							className='landingPage-logos-logoTimescope'
							style={{
								backgroundImage: `url(${timescopeLogo})`,
							}}
						></div>
					</div>
					<div className='infoPage-blankBottom'></div>
				</div>
			</div>
		</div>
	);
};
