// React
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLanguage } from "../../actions/user";
// Components
// Styles
import "./lang.css";
// Assets
import timescopeLogo from "../../style/assets/home/logoTimescope.svg";
import { GetTrad } from "../Partials/GetTrad";

export const Lang = (props: any) => {
	const dispatch: Dispatch<any> = useDispatch();

	const [langTmp, setLangTmp] = useState<string>("fr");

	useEffect(() => {
		console.log("Display Lang");
	}, []);

	useEffect(() => {
		let to = setTimeout(() => {
			setLangTmp(langTmp === "fr" ? "en" : "fr");
		}, 5000);

		return () => clearTimeout(to);
	}, [langTmp]);

	return (
		<React.Fragment>
			<div className='lang'>
				<div className='lang-overflowedContainer'>
					<div className='lang-overflowed'>
						<div className='lang-header'>
							<div className='lang-header-logo'></div>
						</div>
						<div className='lang-watermark'>
							<div className='lang-watermarkInside'></div>
						</div>
						<div className='lang-choice'>
							<div className='lang-enter'>
								<GetTrad value='homeEnter1' lang={langTmp} />
								<br />
								<GetTrad value='homeEnter2' lang={langTmp} />
							</div>
							<div
								className='lang-button'
								onClick={() => {
									dispatch(setLanguage("en"));
									props.displayLangPage(false);
								}}
							>
								ENGLISH
							</div>
							<div
								className='lang-button'
								onClick={() => {
									dispatch(setLanguage("fr"));
									props.displayLangPage(false);
								}}
							>
								FRANÇAIS
							</div>
						</div>

						<div className='lang-logos'>
							<div className='lang-logos-collab'>
								<GetTrad value='homeCollab' lang={langTmp} />
							</div>
							<div
								className='lang-logos-logo'
								style={{
									backgroundImage: `url(${timescopeLogo})`,
								}}
							></div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
