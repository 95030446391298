// React
import { useEffect, useRef, useState } from "react";
// Styles
import "./intro.css";
// Assets
import vignetteStrollMarly from "../../style/assets/intro/vignetteStrollMarly.jpg";
import vignetteFeastMarly from "../../style/assets/intro/vignetteFeastMarly.jpg";
import timescopeLogo from "../../style/assets/home/logoTimescope.svg";
import { useSelector } from "react-redux";
import { IRootState } from "../../reducers";
import { GetTrad } from "../Partials/GetTrad";

export const Intro = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.user);

	return (
		<div id='intro' className='intro'>
			<div id='intro-overflowed' className='intro-overflowed'>
				<div id='intro-header' className='intro-header'>
					<div className='intro-header-back' onClick={() => props.back()}></div>
					<div className='intro-header-logo'></div>
				</div>
				{/* <div className='intro-ornement'></div> */}
				<div className='intro-intro'>
					<div className='intro-title'>
						{/* <GetTrad value='introTitle' lang={language} /> */}<br/>
					</div>
					<div className='intro-subtitle'>
						<GetTrad value='introSubtitle' lang={language} />
					</div>
				</div>

				<div className='intro-bloc'>
					{/* <div className='intro-bloc-header'>
						{trads.find((t: any) => t.id === language)?.strollTitlePart1}
						<span className='intro-bloc-headerPart2'>
							{trads.find((t: any) => t.id === language)?.strollTitlePart2}
						</span>
					</div> */}
					<div className='intro-bloc-videoTrailerContainer'>
						<div
							className='intro-bloc-videoTrailer btnStart'
							onClick={() => {
								props.infos("feast");
							}}
							style={{ backgroundImage: `url(${vignetteFeastMarly})` }}
						>
							<div className='intro-bloc-videoTrailerOverlay'></div>
							<div className='intro-bloc-videoTrailer-playIcon'></div>
							<div className='intro-bloc-videoTrailer-timer'>▶ 2:38</div>
							{/* <div className='intro-bloc-videoTrailer-newIcon'>
										<div className='intro-bloc-videoTrailer-newIconText'>
											{trads.find((t: any) => t.id === language)?.new}
										</div>
									</div> */}
						</div>
					</div>
				</div>
				<div className='intro-bloc'>
							{/* <div className='intro-bloc-header'>
								{trads.find((t: any) => t.id === language)?.strollTitlePart1}
								<span className='intro-bloc-headerPart2'>
									{trads.find((t: any) => t.id === language)?.strollTitlePart2}
								</span>
							</div> */}
							<div className='intro-bloc-videoTrailerContainer'>
								<div
									className='intro-bloc-videoTrailer btnStart'
									onClick={() => {
										props.infos("stroll");
									}}
									style={{ backgroundImage: `url(${vignetteStrollMarly})` }}
								>
									<div className='intro-bloc-videoTrailerOverlay'></div>
									<div className='intro-bloc-videoTrailer-playIcon'></div>
									<div className='intro-bloc-videoTrailer-timer'>▶ 3:03</div>
								</div>
							</div>
						</div>
				<div className='lang-logos'>
					<div className='lang-logos-collab'>
						<GetTrad value='homeCollab' lang={language} />
					</div>
					<div
						className='lang-logos-logo'
						style={{
							backgroundImage: `url(${timescopeLogo})`,
						}}
					></div>
				</div>
			</div>
		</div>
	);
};
